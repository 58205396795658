<template>
  <b-form>
    <b-card title="Güncelle">
      <b-form-group label="Başlık">
        <b-form-input
          v-model="education.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group
        label="Eğitim Tarihi"
        label-for="edate"
      >
        <b-form-datepicker
          id="edate"
          v-model="education.edate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
      <b-form-group label="Metin">
        <b-form-textarea
          v-model="education.content"
          placeholder="Metin"
        />
      </b-form-group>
    </b-card>
    <b-card title="Dosya">
      <b-row>
        <b-col
          v-if="education.file"
          cols="2"
        >
          <b-button
            variant="primary"
            :href="folderUrl + education.file"
            target="_blank"
            block
          >
            <FeatherIcon
              icon="DownloadIcon"
              size="48"
            />
            <br>
            Dosya İndir
          </b-button>
        </b-col>
        <b-col>
          <b-form-group label="Dosya Yükle">
            <b-form-file
              v-model="education.upload_doc"
              placeholder="Bir dosya seçin veya buraya bırakın..."
              drop-placeholder="Dosyayı buraya bırakın..."
              browse-text="Dosya Seçin"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Galeri Fotoğrafları">
      <b-form-group label="Fotoğraf Yükle">
        <b-form-file
          v-model="education.upload_images"
          placeholder="Bir görsel seçin veya buraya bırakın..."
          drop-placeholder="Görseli buraya bırakın..."
          browse-text="Fotoğrafları Seçin"
          multiple
        />
      </b-form-group>
      <hr>
      <b-row class="match-height">
        <b-col
          v-for="image in education.gallery"
          :key="image.id"
          xs="12"
          sm="6"
          md="3"
          class="text-center mb-2"
        >
          <b-img
            rounded
            :src="folderUrl+image.name"
            fluid
          />
          <b-button
            variant="outline-danger"
            block
            class="mt-1"
            size="sm"
            @click="removeImage(image.id)"
          >
            <FeatherIcon icon="XIcon" /> Fotoğrafı Sil
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormGroup,
  BButton,
  BFormDatepicker,
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'

export default {
  name: 'Edit',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BRow,
    BCol,
    BImg,
  },
  data() {
    return {
      submitStatus: false,
      folderUrl: null,
    }
  },
  computed: {
    education() {
      const data = this.$store.getters['educations/getEducation']
      if (data.file) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.folderUrl = `${this.$store.state.app.baseURL}/media/portal/educations/`
      }
      return data
    },
    saveStatus() {
      return this.$store.getters['educations/getEducationSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getEducation()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  beforeCreate() {
    this.$store.dispatch('educations/educationReset')
  },
  created() {
    this.getEducation()
  },
  methods: {
    getEducation() {
      this.$store.dispatch('educations/educationView', this.$route.params.id)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.education.submitStatus = true
      this.$store.dispatch('educations/educationSave', this.education)
    },
    removeImage(id) {
      this.$store.dispatch('educations/educationsDeleteImage', id)
    },
  },
}
</script>
